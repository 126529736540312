const ACTIVITY_TIMESTAMP_STORAGE_KEY = 'korioActivityTimestamp';

export const INACTIVITY_LIMIT = 60 * 15; // Fifteen minutes
export const INACTIVITY_COUNTDOWN = 60; // Sixty seconds

export const setActivityTimestamp = activityTimestamp => {
  localStorage.setItem(ACTIVITY_TIMESTAMP_STORAGE_KEY, activityTimestamp);
};

export const getActivityTimestamp = () => {
  return localStorage.getItem(ACTIVITY_TIMESTAMP_STORAGE_KEY) ?? Date.now();
};
