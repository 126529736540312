import React, { useRef, useState, useEffect, useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { ArrowCircleRight as ArrowCircleRightIcon } from '@mui/icons-material';
import { TYPE } from 'utils/alerts';
import { sendPasswordResetEmail } from 'api/users';

const EnterEmail = ({ setIsLoading }) => {
  const [, setSearchParams] = useSearchParams();
  const { enqueueSnackbar } = useSnackbar(); 

  const inputRef = useRef();

  const [email, setEmail] = useState('');

  const onSubmit = useCallback(() => {
    setIsLoading(true);

    sendPasswordResetEmail(email).then(() => {
      enqueueSnackbar('Verification email has been sent.', { 
        variant: TYPE.SUCCESS,
      });

      setSearchParams({ email });

      setIsLoading(false);
    }, () => {
      enqueueSnackbar('Unable to find an account associated with that email address.', { 
        variant: TYPE.ERROR,
      });

      setIsLoading(false);
    });
  }, [email, setIsLoading, setSearchParams, enqueueSnackbar]);

  const onKeyDown = useCallback(({ keyCode }) => {
    if (keyCode === 13) {
      onSubmit();
    }
  }, [onSubmit]);

  const onChange = useCallback(({ target }) => {
    setEmail(target.value);
  }, []);

  useEffect(() => {
    inputRef.current.focus();
  }, []);

  return (
    <section>
      <p>To reset your password we will need to send you an account verification code. Please enter the email address associated with your account.</p>
      <div className="input-wrapper">
        <input
          ref={inputRef}
          type="email"
          placeholder="email@example.com"
          value={email}
          onChange={onChange}
          onKeyDown={onKeyDown}
        />
        <div 
          className="btn-next"
          onClick={onSubmit}>
          <ArrowCircleRightIcon /> 
        </div>
      </div>
    </section>
  );
};

export default EnterEmail;
