import { get } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    name: null,
    role: null,
    unblindingPin: null,
  },
  reducers: {
    initUser: (state, action) => {
      state.name = get(action.payload, 'name', null);
      state.role = get(action.payload, 'role', null);
      state.unblindingPin = get(action.payload, 'unblinding_pin', null);
    },
    setUnblindingPin: (state, action) => {
      state.unblindingPin = action.payload;
    },
  },
});

export const {
  initUser,
  setUnblindingPin,
} = userSlice.actions;

export default userSlice.reducer;
