import React, {
  createRef, 
  useState, 
  useCallback, 
  useMemo,
  useEffect,
} from 'react';
import './styles.scss';

const Pinput = ({
  pinLength = 6,
  onChange = () => {},
  autoFocus = false,
}) => {
  const initialValues = useMemo(() => [...Array(pinLength)].map(() => ''), [pinLength]);

  const [inputValues, setInputValues] = useState(initialValues);

  const inputRefs = useMemo(() => {
    return initialValues.map(() => createRef(null));
  }, [initialValues]);

  const setInputValueAtIndex = useCallback((index, value) => {
    const nextInputValues = [...inputValues];

    nextInputValues[index] = value;

    setInputValues(nextInputValues);

    onChange(nextInputValues.join(''), index);
  }, [inputValues]);

  const onInputChange = useCallback((index, { target }) => {
    setInputValueAtIndex(index, target.value);

    if (index + 1 < pinLength && target.value !== '') {
      inputRefs[index + 1].current.focus();
    }
  }, [pinLength, setInputValueAtIndex]);

  const onInputKeyDown = useCallback((index, { keyCode, target }) => {
    if (keyCode === 8) {
      if (index > 0 && target.value === '') {
        inputRefs[index - 1].current.focus();
      }
    }
  }, []);

  const onPaste = useCallback(({ clipboardData }) => {
    const clipboardText = clipboardData.getData('text');

    const nextInputValues = inputValues.map((v, i) => {
      return clipboardText.charAt(i) ?? v;
    });

    setInputValues(nextInputValues);

    inputRefs[Math.min(clipboardText.length, pinLength) - 1].current.focus();

    onChange(nextInputValues.join(''));
  }, [inputValues, pinLength]);

  useEffect(() => {
    if (autoFocus) {
      inputRefs[0].current.focus();
    }
  }, [autoFocus]);

  return (
    <div className="pinput-container">
      {inputValues.map((inputValue, idx) => {
        return (
          <input
            key={`pin-input-${idx}`}
            ref={inputRefs[idx]}
            value={inputValue}
            onChange={onInputChange.bind(this, idx)}
            onKeyDown={onInputKeyDown.bind(this, idx)}
            onPaste={onPaste}
            maxLength="1"
          />
        );
      })}
    </div>
  );
};

export default Pinput;
