import React, { useState, useMemo, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { LoadingIndicator } from 'components';
import { EnterEmail, VerifyAccount, ResetPassword, ResetComplete } from './components';

const STEP = {
  EMAIL: { component: EnterEmail },
  VERIFY: { component: VerifyAccount },
  RESET: { component: ResetPassword },
  COMPLETE: { component: ResetComplete }, 
};

const ResetPasswordPage = () => {
  const [searchParams] = useSearchParams();

  const [step, setStep] = useState(STEP.EMAIL);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const email = searchParams.get('email');
    const pin = searchParams.get('pin');
    const success = searchParams.get('success');

    if (!!email && !pin) {
      setStep(STEP.VERIFY);
    } else if (!!email && !!pin) {
      setStep(STEP.RESET);
    } else if (!!success) {
      setStep(STEP.COMPLETE);
    }
  }, [searchParams]);

  const Component = useMemo(() => step.component, [step]);

  return (
    <div id="reset-password-page">
      <LoadingIndicator isLoading={isLoading} />
      <header>
        <h1>Reset Password</h1>
      </header>
      <Component setIsLoading={setIsLoading} />
    </div>
  );
};

export default ResetPasswordPage;
