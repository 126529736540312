import { get } from 'lodash';
import { createSelector } from 'reselect';
import { PIN_REQUIRED_ROLES } from 'utils/roles';

export const getName = state => get(state, 'user.name', {});
export const getRole = state => get(state, 'user.role');
export const getUnblindingPin = state => get(state, 'user.unblindingPin');

export const getUserDisplayName = createSelector(getName, name => {
  if (!name) return null;

  return `${name.first} ${name.last}`;
});

export const getUserShouldSetPin = createSelector(
  [getRole, getUnblindingPin], 
  (role, unblindingPin) => {
    return !unblindingPin && PIN_REQUIRED_ROLES.includes(role);
  },
);
