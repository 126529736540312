import React, { useCallback } from 'react';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

const UserStudyItem = ({
  appBasePath = 'app',
  sponsorName,
  studyName,
  studyDescription,
  tenantId,
  studyId,
}) => {
  const onClick = useCallback(() => {
    window.location.href = `/${appBasePath}/?basePath=${appBasePath}&tenantId=${tenantId}&studyId=${studyId}`;
  }, [appBasePath, tenantId, studyId]);

  return (
    <li className="user-study-item" onClick={onClick} onKeyDown={onClick}>
      <header>
        <h4>{sponsorName}</h4>
      </header>
      <section>
        <div className="study-details">
          <h3>{studyName}</h3>
          <p>{studyDescription}</p>
        </div>
        <div className="action-text">
          <span>View Study</span>
          <ChevronRightIcon />
        </div>
      </section>
    </li>
  );
};

export default UserStudyItem;

