import { LogLevel, BrowserCacheLocation } from '@azure/msal-browser';

export const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_AUTH_CLIENT_ID,
    authority: process.env.REACT_APP_AUTH_AUTHORITY,
    redirectUri: process.env.REACT_APP_REDIRECT_URI,
    postLogoutRedirectUri: process.env.REACT_APP_REDIRECT_URI,
    knownAuthorities: [process.env.REACT_APP_AUTH_KNOWN_AUTHORITY],
    navigateToLoginRequestUrl: true,
    validateAuthority: true,
  },
  cache: {
    cacheLocation: BrowserCacheLocation.SessionStorage,
    storeAuthStateInCookie: false,
  },
  system: {
    loggerOptions: {
      loggerCallback: (logLevel, message, containsPii) => {
        if (containsPii) return;

        switch (logLevel) {
        case LogLevel.Error:
          console.error(message);

          return;
        case LogLevel.Warning:
          console.warn(message);

          return;
        case LogLevel.Info:
          console.info(message);

          return;
        case LogLevel.Verbose:
          console.debug(message);

          return;
        default:
          console.log(message);
        }
      },
    },
  },
};

export const loginRequest = {
  scopes: [
    process.env.REACT_APP_SCOPE_1 || '',
    process.env.REACT_APP_SCOPE_2 || '',
    process.env.REACT_APP_SCOPE_3 || '',
    process.env.REACT_APP_CLIENT_ID || '',
  ],
  forceRefresh: true,
  refreshTokenExpirationOffsetSeconds: 15 * 60,
};
