import { axiosRef } from 'config/axios';

export const fetchAllStudies = ({ authToken }) => {
  return new Promise((resolve, reject) => {
    axiosRef.get('studies/allProtocols', {
      headers: {
        'Authorization': `Bearer ${authToken}`,
      },
    }).then(({ data }) => {
      resolve(data?.data?.userStudies);
    }).catch(error => {
      reject(error);
    });
  });
};
