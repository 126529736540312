import React from 'react';
import { korio } from 'assets';
import './styles.scss';

const AccountFlow = ({ children }) => {
  return (
    <div className="account-flow-layout">
      <header>
        <img src={korio} className="korio-logo" alt="Korio" />
      </header>
      <section>
        <div className="wrapper">
          {children}
        </div>
      </section>
    </div>
  );
};

export default AccountFlow;
