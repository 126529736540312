import React from 'react';
import { NavigationPanel } from 'components';
import './styles.scss';

const LayoutDefault = ({ children }) => {
  return (
    <div className="layout-default">
      <article>
        <NavigationPanel />
      </article>
      <section>
        {children}
      </section>
    </div>
  );
};

export default LayoutDefault;
