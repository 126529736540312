export const ROLE = {
  MEDICAL_MONITOR: 'Medical Monitor',
  INVESTIGATOR: 'Investigator',
  DRUG_SAFETY: 'Drug Safety',
};

export const PIN_REQUIRED_ROLES = [
  ROLE.MEDICAL_MONITOR,
  ROLE.INVESTIGATOR,
  ROLE.DRUG_SAFETY,
];
