import { createSlice } from '@reduxjs/toolkit';

export const studiesSlice = createSlice({
  name: 'studies',
  initialState: {
    userStudies: [],
  },
  reducers: {
    setUserStudies: (state, action) => {
      state.userStudies = action.payload;
    },
  },
});

export const {
  setUserStudies,
} = studiesSlice.actions;

export default studiesSlice.reducer;
