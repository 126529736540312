import React, { useCallback, useMemo } from 'react';
import { useSearchParams, Navigate } from 'react-router-dom';
import { useMsal, useIsAuthenticated } from '@azure/msal-react';

const CreateComplete = () => {
  const [searchParams] = useSearchParams();
  const isAuthenticated = useIsAuthenticated();
  const { instance } = useMsal();

  const email = useMemo(() => searchParams.get('email'), [searchParams]);

  const goToLogin = useCallback(async () => {
    await instance.loginRedirect({ prompt: 'login' });
  }, [instance]);

  if (isAuthenticated) {
    return (<Navigate to="/" replace />);
  }

  return (
    <section>
      <p>Your account has been created. Your login email is <strong>{email}</strong>.</p>

      <p
        className="action-text"
        onClick={goToLogin}>
        Click here to return to the login screen.
      </p>
    </section>
  );
};

export default CreateComplete;
