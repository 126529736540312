import React, { useRef, useState, useEffect, useCallback, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { ArrowCircleRight as ArrowCircleRightIcon } from '@mui/icons-material';
import { Checkbox } from 'components';
import { TYPE } from 'utils/alerts';
import { PASSWORD_REGEX, WEAK_PASSWORD_MESSAGE, PRIVACY_POLICY_LINK } from 'utils/account';
import { createAccount } from 'api/users';

const MODE = {
  CREATE: {
    id: 'create',
    subheading: 'Input your desired password below.',
    placeholder: 'password',
  },
  CONFIRM: {
    id: 'confirm',
    subheading: 'Please confirm your password by entering it again.',
    placeholder: 'confirm password',
  },
};

const ResetPassword = ({ setIsLoading }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { enqueueSnackbar } = useSnackbar(); 

  const inputRef = useRef();

  const [mode, setMode] = useState(MODE.CREATE);

  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [agreed, setAgreed] = useState(false);
  const [agreedError, setAgreedError] = useState(false);

  const email = useMemo(() => searchParams.get('email'), [searchParams]);
  const pin = useMemo(() => searchParams.get('pin'), [searchParams]);

  const handleValidatePassword = useCallback(() => {
    if (PASSWORD_REGEX.test(password)) {
      setMode(MODE.CONFIRM);
    } else {
      enqueueSnackbar(WEAK_PASSWORD_MESSAGE, { variant: TYPE.ERROR });
    }
  }, [password, enqueueSnackbar]);

  const handleCreateAccount = useCallback(() => {
    if (!agreed) {
      setAgreedError(true);
    } else {
      setIsLoading(true);

      createAccount({
        pin,
        email,
        password,
        confirmPassword
      }).then(() => {
        enqueueSnackbar('Account created.', { variant: TYPE.SUCCESS });

        setSearchParams({ email, success: true });
        setIsLoading(false);
      }, error => {
        console.error(error);

        enqueueSnackbar('There was a problem creating your account.', { variant: TYPE.ERROR });

        setPassword('');
        setConfirmPassword('');
        setAgreed(false);
        setMode(MODE.CREATE);
        setIsLoading(false);
      });
    }
  }, [email, pin, password, confirmPassword, agreed, enqueueSnackbar, setSearchParams, setIsLoading]);

  const [value, setValue, onSubmit] = useMemo(() => {
    switch (mode.id) {
    case MODE.CONFIRM.id:
      return [confirmPassword, setConfirmPassword, handleCreateAccount];
    case MODE.CREATE.id:
    default:
      return [password, setPassword, handleValidatePassword];
    }
  }, [
    mode, 
    confirmPassword, 
    setConfirmPassword, 
    handleCreateAccount, 
    password,
    setPassword,
    handleValidatePassword,
  ]);

  const onKeyDown = useCallback(({ keyCode }) => {
    if (keyCode === 13) {
      onSubmit();
    }
  }, [onSubmit]);

  const onChange = useCallback(({ target }) => {
    setValue(target.value);
  }, [setValue]);

  useEffect(() => {
    if ([MODE.CREATE.id, MODE.CONFIRM.id].includes(mode.id)) {
      inputRef.current.focus();
    }
  }, [mode]);

  useEffect(() => {
    if (agreed && agreedError) {
      setAgreedError(false);
    }
  }, [agreed, agreedError]);

  const privacyPolicyLabel = useMemo(() => {
    const link = (<a href={PRIVACY_POLICY_LINK} target="_blank">Privacy Policy</a>);

    return (<>I agree to the {link}.</>);
  }, []);

  return (
    <section>
      <p>{mode.subheading}</p>
      <div className="input-wrapper">
        <input type="email" value={email} disabled />
      </div>
      <div className="input-wrapper">
        <input
          ref={inputRef}
          type="password"
          placeholder={mode.placeholder}
          value={value}
          onChange={onChange}
          onKeyDown={onKeyDown}
        />
        <div 
          className="btn-next"
          onClick={onSubmit}>
          <ArrowCircleRightIcon /> 
        </div>
      </div>

      <footer>
        <Checkbox
          label={privacyPolicyLabel}
          value={agreed}
          onChange={setAgreed}
          error={agreedError}
          required
        />
      </footer>
    </section>
  );
};

export default ResetPassword;
