import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useApi } from 'hooks/api';
import { LoadingIndicator } from 'components';
import { initUser } from 'store/user';
import { fetchCurrentUser } from 'api/users';

const UserProvider = ({ children }) => {
  const dispatch = useDispatch();

  const { data, isLoading } = useApi(fetchCurrentUser);

  useEffect(() => {
    if (!isLoading && !!data) {
      dispatch(initUser(data));
    }
  }, [data, isLoading, dispatch]);

  return (
    <>
      <LoadingIndicator isLoading={isLoading} />
      {children}
    </>
  );
};

export default UserProvider;
