import React, { useState, useMemo, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { LoadingIndicator } from 'components';
import { VerifyAccount, CreatePassword, CreateComplete } from './components';

const STEP = {
  VERIFY: { component: VerifyAccount },
  CREATE_PASSWORD: { component: CreatePassword },
  COMPLETE: { component: CreateComplete },
};

const CreateAccountPage = () => {
  const [searchParams] = useSearchParams();

  const [step, setStep] = useState(STEP.VERIFY);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const email = searchParams.get('email');
    const pin = searchParams.get('pin');
    const success = searchParams.get('success');

    if (!!email && !pin && !success) {
      setStep(STEP.VERIFY);
    } else if (!!email && !!pin) {
      setStep(STEP.CREATE_PASSWORD);
    } else if (!!success) {
      setStep(STEP.COMPLETE);
    }
  }, [searchParams]);

  const Component = useMemo(() => step.component, [step]);

  return (
    <div id="create-account-page">
      <LoadingIndicator isLoading={isLoading} />
      <header>
        <h1>Create Your Account</h1>
      </header>
      <Component setIsLoading={setIsLoading} />
    </div>
  );
};

export default CreateAccountPage;
