import React from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { AuthenticationProvider } from 'components';
import { LayoutDefault, AccountFlow } from 'layouts';
import { Dashboard, CreateAccount, CreatePin, ResetPassword } from 'pages';

const router = createBrowserRouter([{
  path: '/',
  element: (
    <AuthenticationProvider>
      <LayoutDefault>
        <Dashboard />
      </LayoutDefault>
    </AuthenticationProvider>
  ),
}, {
  path: '/create-account',
  element: (
    <AccountFlow>
      <CreateAccount />
    </AccountFlow>
  ),
}, {
  path: '/create-pin',
  element: (
    <AuthenticationProvider>
      <AccountFlow>
        <CreatePin />
      </AccountFlow>
    </AuthenticationProvider>
  ),
}, {
  path: '/reset-password',
  element: (
    <AccountFlow>
      <ResetPassword />
    </AccountFlow>
  ),
}]);

const App = () => {
  return (
    <div id="app-container">
      <RouterProvider router={router} />
    </div>
  );
};

export default App;
