import React from 'react';
import ReactDOM from 'react-dom/client';
import { SnackbarProvider } from 'notistack';
import { Provider as ReduxProvider } from 'react-redux';
import { MsalProvider } from '@azure/msal-react';
import { PublicClientApplication } from '@azure/msal-browser';
import { msalConfig } from 'config/msal';
import { SNACKBAR_CONFIG } from 'utils/alerts';
import store from 'store';
import App from './App';
import './index.css';

const msalInstance = new PublicClientApplication(msalConfig);

msalInstance.initialize();

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <ReduxProvider store={store}>
        <SnackbarProvider {...SNACKBAR_CONFIG}>
          <App />
        </SnackbarProvider>
      </ReduxProvider>
    </MsalProvider>
  </React.StrictMode>
);
