import { configureStore } from '@reduxjs/toolkit';
import studiesReducer from './studies';
import userReducer from './user';

export default configureStore({
  reducer: {
    studies: studiesReducer,
    user: userReducer,
  },
});
