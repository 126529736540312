import React, { useCallback } from 'react';
import { useMsal, useIsAuthenticated } from '@azure/msal-react';
import { Navigate } from 'react-router-dom';

const ResetComplete = () => {
  const isAuthenticated = useIsAuthenticated();
  const { instance } = useMsal();

  const goToLogin = useCallback(async () => {
    await instance.loginRedirect({ prompt: 'login' });
  }, [instance]);

  if (isAuthenticated) {
    return (<Navigate to="/" replace />);
  }

  return (
    <section>
      <p
        className="action-text"
        onClick={goToLogin}>
        Your password has been successfully reset. Click here to return to the login screen.
      </p>
    </section>
  );
};

export default ResetComplete;
