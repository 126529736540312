import React, { useMemo, useEffect, useState, useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { Pinput } from 'components';
import { TYPE } from 'utils/alerts';
import { resendVerificationCode, validateVerificationCode } from 'api/users';

const PIN_LENGTH = 6;

const VerifyAccount = ({ setIsLoading }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { enqueueSnackbar } = useSnackbar(); 

  const [pin, setPin] = useState('');

  const email = useMemo(() => searchParams.get('email'), [searchParams]);

  const onResendCodeClick = useCallback(() => {
    setIsLoading(true);

    resendVerificationCode(email).then(() => {
      enqueueSnackbar('Verification email has been sent.', { 
        variant: TYPE.SUCCESS,
      });

      setIsLoading(false);
    }, () => {
      enqueueSnackbar('We were unable to resend the verification email.', { 
        variant: TYPE.ERROR,
      });

      setIsLoading(false);
    })
  }, [email, enqueueSnackbar, setIsLoading]);

  const onSubmit = useCallback(() => {
    // This prevents API from being called twice after success
    if (!!searchParams.get('pin')) return;

    setIsLoading(true);

    validateVerificationCode({ email, pin }).then(({ data }) => {
      enqueueSnackbar('Account verified.', { 
        variant: TYPE.SUCCESS,
      });

      setSearchParams({ email, pin: data });

      setIsLoading(false);
    }, () => {
      enqueueSnackbar('We were unable to verify your account.', { 
        variant: TYPE.ERROR,
      });

      setIsLoading(false);
    });
  }, [email, pin, searchParams, setIsLoading, setSearchParams, enqueueSnackbar]);

  useEffect(() => {
    if (pin.length === PIN_LENGTH) {
      onSubmit();
    }
  }, [pin, onSubmit]);

  return (
    <section>
      <p>Please enter the verification code sent to <strong>{email}</strong> to continue.</p>
      <Pinput
        pinLength={PIN_LENGTH} 
        onChange={setPin}
        autoFocus
      />
      <footer 
        className="action-text"
        onClick={onResendCodeClick}>
        Need a new code? Click here to resend the verification code.
      </footer>
    </section>
  );
};

export default VerifyAccount;
