import { axiosRef } from 'config/axios';

export const fetchCurrentUser = ({ authToken }) => {
  return new Promise((resolve, reject) => {
    axiosRef.get('user/users/current/me', {
      headers: { 'Authorization': `Bearer ${authToken}` },
    }).then(({ data }) => {
      resolve(data?.data);
    }).catch(error => {
      reject(new Error(error));
    });
  });
};

export const createAccount = payload => {
  return new Promise((resolve, reject) => {
    axiosRef.post('auth/signUp', payload).then(({ data }) => {
      resolve(data);
    }).catch(error => {
      reject(new Error(error));
    });
  });
};

export const createUserPin = (payload, { authToken }) => {
  return new Promise((resolve, reject) => {
    axiosRef.post('user/users/createPin', payload, {
      headers: { 'Authorization': `Bearer ${authToken}` },
    }).then(({ data }) => {
      resolve(data?.data);
    }).catch(error => {
      reject(new Error(error));
    });
  });
};

export const sendPasswordResetEmail = email => {
  return new Promise((resolve, reject) => {
    axiosRef.get(`auth/forgotPassword/${email}`).then(resp => {
      resolve(resp);
    }).catch(error => {
      reject(new Error(error));
    });
  });
};

export const resendVerificationCode = email => {
  return new Promise((resolve, reject) => {
    axiosRef.get(`auth/resendPin/${email}`).then(resp => {
      resolve(resp);
    }).catch(error => {
      reject(new Error(error));
    });
  });
};

export const validateVerificationCode = payload => {
  return new Promise((resolve, reject) => {
    axiosRef.post('auth/validatePin', payload).then(({ data }) => {
      resolve(data);
    }).catch(error => {
      reject(new Error(error));
    });
  });
};

export const resetPassword = payload => {
  return new Promise((resolve, reject) => {
    axiosRef.post('auth/reset-password', payload).then(resp => {
      resolve(resp);
    }).catch(error => {
      reject(new Error(error));
    });
  });
};
