import React, { useMemo, useEffect } from 'react';
import { useIsAuthenticated } from '@azure/msal-react';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { INACTIVITY_LIMIT, INACTIVITY_COUNTDOWN } from 'utils/auth';
import './styles.scss';

const InactivityAlert = ({ inactivitySeconds, recordUserActivity }) => {
  const inactivityCountdown = useMemo(() => {
    return Math.max(INACTIVITY_LIMIT - inactivitySeconds, 0);
  }, [inactivitySeconds]);

  return (
    <div className="inactivity-alert-container">
      <div className="inactivity-alert">
        <header>
          <WarningAmberIcon className="ic-warning" />
          <h3>Warning</h3>
        </header>

        <section>
          <span>You will be logged out in&nbsp;</span>
          <span className="bold">{inactivityCountdown}&nbsp;</span>
          <span>seconds due to inactivity</span>
        </section>

        <footer>
          <button onClick={recordUserActivity}>
            Keep Me Logged In
          </button>
        </footer>
      </div>
    </div>
  );
};

const InactivityManager = ({
  inactivitySeconds,
  recordUserActivity,
  destroyUserSession,
  children,
}) => {
  const isAuthenticated = useIsAuthenticated();

  useEffect(() => {
    if (!isAuthenticated) return;

    if (inactivitySeconds > INACTIVITY_LIMIT) {
      destroyUserSession();
    }
  }, [isAuthenticated, inactivitySeconds, destroyUserSession]);

  const shouldShowInactivityAlert = useMemo(() => {
    if (!isAuthenticated) return false;

    return inactivitySeconds >= INACTIVITY_LIMIT - INACTIVITY_COUNTDOWN;
  }, [isAuthenticated, inactivitySeconds]);

  return (
    <div id="inactivity-manager">
      {shouldShowInactivityAlert ? (
        <InactivityAlert
          inactivitySeconds={inactivitySeconds}
          recordUserActivity={recordUserActivity}
        />
      ) : null}

      {children}
    </div>
  );
};

export default InactivityManager;
