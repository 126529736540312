import React, { forwardRef, useCallback, useMemo } from 'react';
import { useSnackbar, SnackbarContent } from 'notistack';
import classNames from 'classnames';
import { CloseOutlined as CloseOutlinedIcon } from '@mui/icons-material';
import { ICON, HEADING } from 'utils/alerts';
import './styles.scss';

const Snackbar = forwardRef(({ id, message, variant }, ref) => {
  const { closeSnackbar } = useSnackbar();

  const onClose = useCallback(() => {
    closeSnackbar(id);
  }, [id, closeSnackbar]);

  const classes = useMemo(() => classNames(variant, 'snackbar'), [variant]);

  return (
    <SnackbarContent ref={ref} className={classes}>
      <header>
        {ICON[variant]}
        <span>
          {HEADING[variant]}
        </span>
        <CloseOutlinedIcon className="close-icon" onClick={onClose}/>
      </header>
      <p>{message}</p>
    </SnackbarContent>
  );
});

export default Snackbar;
