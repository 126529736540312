import React from 'react';
import { CircularProgress } from '@mui/material';
import './styles.scss';

const LoadingIndicator = ({ isLoading }) => {
  if (!isLoading) return null;

  return (
    <div className="loading-indicator">
      <CircularProgress />
    </div>
  );
};

export default LoadingIndicator;
