import React, { useRef, useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { useMsal } from '@azure/msal-react';
import {
  Avatar,
  Menu,
  IconButton,
  MenuItem,
  ListItemIcon } from '@mui/material';
import {
  Person as PersonIcon,
  Settings as SettingsIcon,
  Logout as LogoutIcon,
} from '@mui/icons-material';
import { korio } from 'assets';
import { getUserDisplayName } from 'store/user/selectors';
import './styles.scss';

const MENU_TRANSFORM_ORIGIN = { horizontal: 'right', vertical: 'top' };
const MENU_ANCHOR_ORIGIN = { horizontal: 'right', vertical: 'bottom' };

const SettingsMenu = ({ isOpen, onClose, anchorEl }) => {
  const { instance } = useMsal();

  const logoutHandler = useCallback(() => {
    instance.logoutRedirect();
  }, [instance]);

  return (
    <Menu
      id="settings-menu"
      open={isOpen}
      anchorEl={anchorEl}
      onClose={onClose}
      transformOrigin={MENU_TRANSFORM_ORIGIN}
      anchorOrigin={MENU_ANCHOR_ORIGIN}>
      <MenuItem onClick={logoutHandler}>
        <ListItemIcon>
          <LogoutIcon />
        </ListItemIcon>
        Logout
      </MenuItem>
    </Menu>
  );
};

const SettingsButton = () => {
  const settingsButtonRef = useRef();

  const [isOpen, setIsOpen] = useState(false);

  const onClick = useCallback(() => {
    setIsOpen(prev => !prev);
  }, []);

  const onClose = useCallback(() => {
    setIsOpen(false);
  }, []);

  return (
    <>
      <IconButton
        ref={settingsButtonRef}
        onClick={onClick}>
        <SettingsIcon />
      </IconButton>

      <SettingsMenu
        isOpen={isOpen}
        onClose={onClose}
        anchorEl={settingsButtonRef.current}
      />
    </>
  );
};

const NavigationPanel = () => {
  const userDisplayName = useSelector(getUserDisplayName);

  return (
    <div className="navigation-panel">
      <header>
        <img src={korio} className="korio-logo" alt="Korio" />
      </header>

      <section />

      <footer>
        <Avatar><PersonIcon /></Avatar>
        <div className="display-name">{userDisplayName}</div>
        <SettingsButton />
      </footer>
    </div>
  );
};

export default NavigationPanel;
