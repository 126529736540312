import React, { useCallback } from 'react';
import classNames from 'classnames';
import './styles.scss';

const Checkbox = ({ 
  label = '', 
  value = false, 
  onChange = () => {},
  required = false,
  error = false,
}) => {
  const handleChange = useCallback(() => {
    onChange(!value);
  }, [value, onChange]);

  return (
    <label className={classNames({ error }, 'checkbox-wrapper')}>
      <span className={classNames({ required }, 'label')}>{label}</span>
      <input type="checkbox" checked={value} onChange={handleChange} />
      <span className="checkmark" />
    </label>
  );
};

export default Checkbox;
